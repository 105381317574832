.customContainer {
    padding-left: 200px;
    padding-right: 200px;
}

@media (max-width: 1200px) {
    .customContainer {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media (max-width: 800px) {
    .customContainer {
        padding-left: 20px;
        padding-right: 20px;
    }
}